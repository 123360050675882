

















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import QuestionBankListingTemplate from '@/components/question/listing/QuestionBankListingTemplate.vue';
import { FormStores } from '@/store/forms';
import { SubjectStores } from '@/store/subjects';
import { DropdownItem } from '@/models/dropdown';
import { QuestionBankStores } from '@/store/question_banks';
import { CreateQuestionBankListForAxios, CreateQuestionBankListForm } from '@/models/question_banks';
import { uploadAttachment } from '@/utils/attachments';

@Component({
  components: { Page, BackButtonLayout, QuestionBankListingTemplate },
})
export default class AddQuestionBank extends Vue {
  subjectListStore = SubjectStores.list;
  levelListStore = FormStores.list;
  questionBankCreateStore = QuestionBankStores.create;
  questionBankStateStore = QuestionBankStores.state;
  subjectData: DropdownItem[] = [];
  levelData: DropdownItem[] = [];
  // Autofill variables
  questionBankListingSubjectPrefixId = -1;
  questionBankListingLevelPrefixId = -1;

  get backRoute() {
    return `/question_banks/listing`;
  }

  get title() {
    return `Add New Listing`;
  }

  get subjects() {
    return this.subjectData;
  }

  get levels() {
    return this.levelData;
  }

  mounted() {
    this.subjectListStore.retrieveSubjects({});
    switch(this.questionBankStateStore.questionBankListingFolderState.length) {
      case 2: {
        this.questionBankListingSubjectPrefixId = this.questionBankStateStore.questionBankListingFolderState[this.questionBankStateStore.questionBankListingFolderState.length - 1].id;
        this.getFormDropdown(this.questionBankListingSubjectPrefixId);
        break;
      }
      case 3: {
        this.questionBankListingSubjectPrefixId = this.questionBankStateStore.questionBankListingFolderState[this.questionBankStateStore.questionBankListingFolderState.length - 2].id;
        this.questionBankListingLevelPrefixId= this.questionBankStateStore.questionBankListingFolderState[this.questionBankStateStore.questionBankListingFolderState.length - 1].id;
        this.getFormDropdown(this.questionBankListingSubjectPrefixId);
        break;
      }
      default: {
        break;
      }
    }
  }

  getFormDropdown(subjectId: number) {
    this.levelListStore.retrieveForms({ subjectid: subjectId });
  }

  @Watch(`subjectListStore.response`)
  updateSubjectDropdown() {
    if (this.subjectListStore.response) {
      this.subjectData = [];
      this.subjectListStore.response.objects.forEach((item) => {
        this.subjectData.push({
          text: item.subjectName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  @Watch(`levelListStore.response`)
  updateFormDropdown() {
    if (this.levelListStore.response) {
      this.levelData = [];
      this.levelListStore.response.objects.forEach((item) => {
        this.levelData.push({
          text: item.formName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  async createNewQuestionBankList(payload: CreateQuestionBankListForm) {
    const input: Partial<CreateQuestionBankListForAxios> = {
      name: payload.name,
      description: payload.description,
      subjectId: payload.subjectId,
      formId: payload.formId,
      tuitionId: 1,
      discountPrice: payload.discountPrice.length > 0 ? Number(payload.discountPrice) : 0,
      price: Number(payload.price),
      availability: payload.availability ? 1 : 0,
      questionType: payload.questionType,
    };

    if (payload.thumbnail && typeof payload.thumbnail !== 'string') {
      const thumbnailResponse = await uploadAttachment(
        payload.thumbnail,
        payload.name,
        `thumbnail`
      );
      input.image = thumbnailResponse;
    }

    this.questionBankCreateStore.createNewQuestionBank(input);
  }

  @Watch(`questionBankCreateStore.response`)
  redirectToQuestionBankListing() {
    if (this.questionBankCreateStore.response) {
      this.questionBankStateStore.updateQuestionBankListingIsModified(true);
      this.$router.push({
        path: `/question_banks/listing`
      });
    }
  }
}
